<template>
  <BaseDialog
    :propDialog="dialog"
    :max-width="500"
    title="dish.menu-settings"
    :validity="valid"
    :loader="loading"
    @confirm="updateSettings"
    @closeDialog="setDialog"
  >
    <v-container class="px-0">
      <v-form ref="form" v-model="valid" @submit.prevent>
        <BaseMultiSelect
          :label="$t('dish.default-dishes')"
          :items="initDishes"
          no-data="dish.no-dish-found"
          v-model="localDefaultDishes"
          :item-text="itemText"
          item-value="dishCode"
        />
        <BaseSwitch
          :label="localShowDishImage ? 'dish.image-on' : 'dish.image-off'"
          v-model="localShowDishImage"
        />
        <BaseSwitch
          :label="localRunCampaign ? 'dish.campaign-on' : 'dish.campaign-off'"
          v-model="localRunCampaign"
        />
        <BaseCurrencyInput
          v-for="(level, index) in localCampaignDetails"
          :key="index"
          :label="$t('dish.tier', { tier: level.tier.toLocaleString() })"
          v-model="level.modifier"
          outlined
          required
          currency="VND"
        />
      </v-form>
    </v-container>
  </BaseDialog>
</template>

<script>
import SettingsService from '@/services/modules/SettingsService'
import { displaySuccessMessage, displayErrorMessage } from '@/utils'
import { mapState } from 'vuex'
import cloneDeep from 'lodash/cloneDeep'

export default {
  name: 'MenuSettings',

  props: {
    dialog: Boolean
  },

  data: () => ({
    localShowDishImage: null,
    localRunCampaign: null,
    localCampaignDetails: null,
    localDefaultDishes: null,
    valid: true,
    loading: false
  }),

  computed: {
    ...mapState('common', [
      'showDishImage',
      'runCampaign',
      'campaignDetails',
      'defaultDishes'
    ]),
    ...mapState('dish', ['initDishes']),

    itemText() {
      if (this.$i18n.locale === 'vi') {
        return 'viName'
      } else {
        return 'enName'
      }
    }
  },

  mounted() {
    this.localShowDishImage = cloneDeep(this.showDishImage)
    this.localRunCampaign = cloneDeep(this.runCampaign)
    this.localCampaignDetails = cloneDeep(this.campaignDetails)
    this.localDefaultDishes = cloneDeep(this.defaultDishes)
  },

  methods: {
    setDialog(val) {
      this.$emit('update:dialog', val)
    },

    updateSettings() {
      this.loading = true
      const settings = {
        showDishImage: this.localShowDishImage,
        runCampaign: this.localRunCampaign,
        campaignDetails: this.localCampaignDetails,
        defaultDishes: this.localDefaultDishes
      }
      SettingsService.update(settings)
        .then(res => {
          this.$store.commit('order/CLEAR_BASKET')
          this.$store.commit('common/SET_SETTINGS', res.data.value)
          this.$store.commit('dish/SET_DISHES', this.initDishes)
          displaySuccessMessage('common.message-update-settings')
          this.$socket.client.emit('update-settings', res.data.value)
          this.setDialog(false)
        })
        .catch(err => displayErrorMessage(err))
        .finally(() => (this.loading = false))
    }
  }
}
</script>

<style scoped></style>
